<template>
	<Bar
		ref="chart"
		:chart-options="chartOptions"
		:chart-data="chartData"
		:chart-id="chartId"
		:dataset-id-key="datasetIdKey"
		:plugins="plugins"
		:css-classes="cssClasses"
		:styles="styles"
		:width="width"
		:height="height"
	/>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default ({
	name: 'BarChart',
	components: {
		Bar
	},
	props: {
		chartId: {
			type: String,
			default: 'bar-chart'
		},

		datasetIdKey: {
			type: String,
			default: 'label'
		},

		width: {
			type: Number,
			default: 300
		},

		height: {
			type: Number,
			default: 500
		},

		cssClasses: {
			default: '',
			type: String
		},

		styles: {
			type: Object,
			default: () => {}
		},

		plugins: {
			type: Object,
			default: () => {}
		},

		chartData: {
			type: Object,
			default: () => {}
		}
	},

	// created() {
	// 	this.renderChart(
	// 		this.chartData,
	// 		this.options
	// 	)
	// },


	// created() {
	// 	console.log(this.$refs);
	// 	setInterval( () => {
	// 		console.log('entrou')
	// 		this.$refs.chart.$data._chart.update()
	// 	}, 2000)
	// },

	data() {
		return {
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						position: 'bottom',
					},
				},
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				}
			}
		}
	}
})
</script>
